import { createRoot } from 'react-dom/client';
import { store } from 'Shared/ifixit_store';
import { Provider } from 'react-redux';
import { withErrorInfo } from 'Shared/logging';
import { initializeReactQuery } from 'Shared/react-query-initialize';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getGrowthbook } from 'Shared/features';
import { ViewLangIntlProvider } from 'Shared/use_intl';

/**
 * This will mount React component `Component` in placeholder divs that have
 * a data-name of `name`.
 *
 * This is used by the ReactComponent.php class to indicate mount points.
 */
const initializeComponent = (name, Component, defaultProps) => {
   onDomReady(() => {
      const selector = `.react-component[data-name="${name}"]`;
      const placeholders = document.querySelectorAll(selector);

      Array.convert(placeholders).forEach(placeholder => {
         // eslint-disable-next-line no-console
         console.time(name);
         let props = {
            ...defaultProps,
            ...JSON.parse(placeholder.dataset.props),
         };

         withErrorInfo(async () => {
            const root = createRoot(placeholder);
            const growthbook = await getGrowthbook();
            root.render(
               initializeReactQuery(
                  <ViewLangIntlProvider>
                     <GrowthBookProvider growthbook={growthbook}>
                        <Provider store={store}>
                           <Component {...props} />
                        </Provider>
                     </GrowthBookProvider>
                  </ViewLangIntlProvider>
               )
            );
         }, ['Error occurred while rendering', Component, 'with props', props]);
         // eslint-disable-next-line no-console
         console.timeEnd(name);
      });
   });
};

/** Reexport our Redux store, because some components expect it defined here */

export default initializeComponent;

export { store } from 'Shared/ifixit_store';
