import { IntlProvider } from '@ifixit/i18n';
import { PropsWithChildren } from 'react';
import 'Shared/global.d';

export function ViewLangIntlProvider({ children }: PropsWithChildren<Record<never, never>>) {
   return (
      <IntlProvider locale={window.App.lang} messages={window.i18nTranslations}>
         {children}
      </IntlProvider>
   );
}
